<template>
  <v-row class="mx-1">
    <v-col
      cols="12"
      style="border:1px solid #dbdade; border-radius:5px;"
    >
      <v-row>
        <!--<v-col
          cols="12"
          md="12"
        >
          <strong class="mt-5">{{ $t('lbl.addOficinas') }}</strong>
        </v-col>-->
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="search.country"
            :items="countries"
            :label="$t('lbl.country')"
            outlined
            dense
            hide-details
            @change="changeCountry"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.country') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="search.state"
            :items="states"
            :label="$t('lbl.provinceEstado')"
            outlined
            dense
            hide-details
            @change="changeState"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.provinceEstado') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="search.city"
            :items="cities"
            :label="$t('lbl.locality')"
            outlined
            dense
            hide-details
            @change="changeCity"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.locality') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="search.operador_id"
            :items="itemsOperador"
            :search-input.sync="searchOperador"
            hide-details
            hide-selected
            :label="$t('menu.operador')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setOperadores"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="search.marca_asociada_id"
            :items="itemsMarcas"
            :search-input.sync="searchMarcas"
            hide-details
            hide-selected
            :label="$t('menu.marcaAsociada')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setMarca"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.marcaAsociada') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="search.hotel_id"
            :items="itemsHotel"
            :search-input.sync="searchHotel"
            hide-details
            hide-selected
            :label="$t('menu.hotels')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="setHotel"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.hotels') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ `${item.name} (${item.state})` }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-divider></v-divider>
        </v-col>

        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="
              search.country ||
                search.state ||
                search.city ||
                search.operador_id ||
                search.marca_asociada_id ||
                search.hotel_id
            "
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <strong class="mt-5">{{ $t('lbl.hotelsFilter') }}</strong><br />
          <template v-if="!hotelesAll.length">
            <span class="pa-6">
              {{ $t('lbl.noHotelsAsociados') }}
            </span>
          </template>
          <template v-else>
            <v-btn
              block
              color="primary"
              class="mb-5"
              @click="asociarAll"
            >
              <v-icon left>
                mdi-arrow-right-bold
              </v-icon>
            </v-btn>
            <div style="background-color: #FFF; height: 250px; overflow-y: auto">
              <perfect-scrollbar ref="scroll">
                <fragment
                  v-for="(hote, indH) in hotelesAll"
                  :key="indH"
                >
                  <v-chip
                    class="my-1"
                    label
                    style="cursor: pointer;"
                    @click="addIdsHotelsAsociadosContrate(hote.id)"
                  >
                    {{ hote.name }}
                  </v-chip>
                  <br />
                </fragment>
              </perfect-scrollbar>
            </div>
          </template>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6">
          <strong class="mt-5">{{ $t('lbl.hotelsAsociado') }}</strong><br />
          <template v-if="!hotelesPactados.length">
            <span class="pa-6">
              {{ $t('lbl.hotelsAsociados') }}
            </span>
          </template>
          <template v-else>
            <v-btn
              block
              color="primary"
              class="mb-5"
              @click="desAsociarAll"
            >
              <v-icon left>
                mdi-arrow-left-bold
              </v-icon>
            </v-btn>
            <div style="background-color: #FFF; height: 250px; overflow-y: auto">
              <perfect-scrollbar>
                <div
                  v-for="(hPacta, indNode) in hotelesPactados"
                  :key="indNode"
                >
                  <v-chip
                    class="my-1"
                    label
                    style="cursor: pointer;"
                    @click="deleteIdsHotelsAsociadosContrate(hPacta.id)"
                  >
                    {{ hPacta.nameComodin }}
                  </v-chip>
                </div>
              </perfect-scrollbar>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline, mdiEraserVariant } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    idExclude: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiEraserVariant,
      },
      hotels: [],
      hotelsFiltrados: [],
      hotelsNomAll: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 30,
        wheelPropagation: false,
        wheelSpeed: 0.7,
      },
      search: {
        country: null,
        state: null,
        city: null,
        operador_id: null,
        marca_asociada_id: null,
        hotel_id: null,
      },
      countries: [],
      states: [],
      cities: [],
      operadores: [],
      itemsOperador: [],
      searchOperador: null,
      itemsMarcas: [],
      allMarcas: [],
      searchMarcas: null,
      itemsHotel: [],
      allHotel: [],
      searchHotel: null,
    }
  },
  computed: {
    hotelesPactados() {
      const hPactados = []
      this.hotelsNomAll.forEach(hote => {
        this.idsHotelsAsociados.forEach(hAsoc => {
          if (hote.id === hAsoc) {
            hPactados.push(hote)
          }
        })
      })

      return hPactados
    },
    hotelesAll() {
      let hNoPactados = []
      if (this.idsHotelsAsociados.length === 0) {
        hNoPactados = this.hotels
      } else {
        this.hotels.forEach(hote => {
          if (!this.idsHotelsAsociados.includes(hote.id)) {
            hNoPactados.push(hote)
          }
        })
      }

      return hNoPactados
    },
    ...mapState({
      idsHotelsAsociados: state => state.app.idsHotelsAsociados,
    }),
  },
  watch: {
    searchOperador(val) {
      if (val !== null && val.length > 1) {
        this.filterOperador(val.toLowerCase())
      }
    },
    searchMarcas(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterMarcas(val.toLowerCase())
      }
    },
    searchHotel(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterHotel(val.toLowerCase())
      }
    },
  },
  created() {
    this.getHoteles()
    this.getOperadores()
  },
  methods: {
    ...mapMutations(['addIdsHotelsAsociadosContrate', 'deleteIdsHotelsAsociadosContrate']),
    filterOperador(v) {
      this.itemsOperador = []
      if (v === '') {
        this.itemsOperador = []
      } else {
        this.itemsOperador = this.operadores.filter(e => e.name.toLowerCase())
      }
    },
    filterMarcas() {
      this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())
    },
    filterHotel() {
      this.itemsHotel = this.allHotel.filter(e => e.name.toLowerCase())
    },
    asociarAll() {
      this.hotelesAll.forEach(element => {
        this.addIdsHotelsAsociadosContrate(element.id)
      })
    },
    desAsociarAll() {
      this.hotelesPactados.forEach(element => {
        this.deleteIdsHotelsAsociadosContrate(element.id)
      })
    },
    getHoteles() {
      const json = {
        page: 1,
        per_page: 100000,
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else if (this.idExclude.length > 0) {
            const arr = []
            res.data.data.forEach(element => {
              if (!this.idExclude.includes(element.id)) {
                arr.push(element)
              }
            })

            this.hotelsNomAll = arr
            this.allHotel = arr
          } else {
            this.hotelsNomAll = res.data.data
            this.allHotel = res.data.data
          }
        })
        .finally(() => {
          this.getCountries()
        })
    },
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.operadores = res.data.data
        })
    },
    setOperadores() {
      this.hotels = []
      this.allMarcas = []
      this.allHotel = []
      this.itemsHotel = []
      this.itemsMarcas = []
      if (this.operadores.filter(e => e.id === this.search.operador_id).length > 0) {
        if (this.operadores.filter(e => e.id === this.search.operador_id)[0].marcas.length > 0) {
          this.allMarcas = this.operadores.filter(e => e.id === this.search.operador_id)[0].marcas
          this.allHotel = this.hotelsNomAll.filter(e => e.operador_id === this.search.operador_id)
          this.itemsMarcas = this.allMarcas
          this.filtersHotels()
        }
      }
    },
    setMarca() {
      this.hotels = []
      this.allHotel = []
      this.itemsHotel = []
      if (this.allMarcas.filter(e => e.id === this.search.marca_asociada_id).length > 0) {
        this.allHotel = this.hotelsNomAll.filter(e => e.marca_asociada_id === this.search.marca_asociada_id)
        this.filtersHotels()
      }
    },
    setHotel() {
      this.hotels = []

      // this.allHotel = this.hotelsNomAll.filter(e => e.id === this.search.hotel_id)
      this.filtersHotels()
    },
    getCountries() {
      this.hotelsNomAll.forEach(hotelsNom => {
        if (!this.countries.includes(hotelsNom.country)) {
          if (hotelsNom.country) {
            this.countries.push(hotelsNom.country)
          }
        }
      })
    },
    changeCountry() {
      this.states = []
      this.search.state = null
      this.search.city = null
      if (this.search.country) {
        const states = this.hotelsNomAll.filter(e => e.country === this.search.country)
        states.forEach(element => {
          if (!this.states.includes(element.state) && element.state) {
            this.states.push(element.state)
          }
        })
      }
      this.filtersHotels()
    },
    changeState() {
      this.cities = []
      this.search.city = null
      if (this.search.state) {
        const states = this.hotelsNomAll.filter(e => e.state === this.search.state)
        states.forEach(element => {
          if (!this.cities.includes(element.city)) {
            this.cities.push(element.city)
          }
        })
      }
      this.filtersHotels()
    },
    changeCity() {
      this.filtersHotels()
    },
    filtersHotels() {
      this.hotels = []
      this.hotelsNomAll.forEach(hotel => {
        let introducir = true
        if (this.search.operador_id) {
          if (this.search.operador_id !== hotel.operador_id) {
            introducir = false
          }
        }
        if (this.search.marca_asociada_id) {
          if (this.search.marca_asociada_id !== hotel.marca_asociada_id) {
            introducir = false
          }
        }
        if (this.search.hotel_id) {
          if (this.search.hotel_id !== hotel.id) {
            introducir = false
          }
        }
        if (this.search.country) {
          if (this.search.country !== hotel.country) {
            introducir = false
          }
        }
        if (this.search.state) {
          if (this.search.state !== hotel.state) {
            introducir = false
          }
        }
        if (this.search.city) {
          if (this.search.city !== hotel.city) {
            introducir = false
          }
        }

        if (introducir) {
          this.hotels.push({
            id: hotel.id,
            name: hotel.nameComodin,
          })
        }
      })
    },

    clearSearch() {
      this.hotels = []
      this.states = []
      this.cities = []
      this.search = {
        country: null,
        state: null,
        city: null,
        operador_id: null,
        marca_asociada_id: null,
        hotel_id: null,
      }
    },
  },
}
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
<style scoped>
.ps {
  height: 100%;
}
</style>
